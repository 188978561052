<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row>
      <v-col>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          color="primary"
        />
      </v-col>
    </v-row>
    <sequential-entrance from-left>
      <v-row class="hidden-md-and-down">
        <v-col
          v-for="(button, index) in menuforstatus"
          :key="index"
          class="justify-space-around"
        >
          <base-material-card
            v-if="!loading"
            :color="button.color"
            :icon="button.icon"
            inline
            link
            :to="button.nav"
          >
            <template v-slot:after-heading>
              <div class="text-h4 font-weight-light">
                {{ button.text }}
              </div>
            </template>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
    <sequential-entrance from-top>
      <v-row>
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-group"
            inline
          >
            <template v-slot:after-heading>
              <div class="text-h3 font-weight-light">
                Partner Traffic
              </div>
            </template>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
    <sequential-entrance from-top>
      <v-row>
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-group"
            inline
          >
            <template v-slot:after-heading>
              <div class="text-h3 font-weight-light">
                Partner Traffic
              </div>
            </template>
            <v-data-table
              :headers="headers"
              :items="getpartners"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Partners</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:[`item.campaigns`]="{ item }">
                <v-btn
                  outlined
                  color="indigo"
                  class="mr-2"
                  :to="'/partnercampaigns/' + item.key"
                >
                  <v-icon>mdi-handshake</v-icon>Campaigns
                </v-btn>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  class="mr-2"
                  color="purple"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
  </v-container>
</template>

<script>
  import moment from 'moment'

  export default {

    data: () => ({
      dialog: false,
      dialogDelete: false,
      search: '',
      series: [{
        name: 'Website Blog',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: 'Social Media',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
      }],
      headers: [
        { text: 'Created', value: 'created', align: 'center', sortable: false },
        { text: 'Company Name', value: 'company', align: 'center', sortable: false },
        { text: 'Client Name', value: 'name', align: 'center', sortable: false },
        { text: 'Total Accounts', value: 'active', align: 'center', sortable: false },
        { text: 'Total Policies', value: 'active', align: 'center', sortable: false },

      ],
      valid: false,
      partners: [],
      partnerstatus: ['NEW', 'APPROVED', 'DECLINED', 'UNQUALIFIED'],
      editedIndex: -1,
      max: 50,
      allowSpaces: false,
      unique: true,
      filterchars: true,
      onlyspace: false,
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Traffic Sources'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
          type: 'datetime'
        },
        yaxis: [{
          title: {
            text: 'Website Blog',
          },

        }, {
          opposite: true,
          title: {
            text: 'Social Media'
          }
        }]
      },
      editedItem: {
        name: '',
        email: '',
        message: '',
        phone: '',
        company: '',
        companywebsite: '',
        industries: '',
        partnerurl: '',
        key: '',
      },
      defaultItem: {
        name: '',
        email: '',
        message: '',
        phone: '',
        company: '',
        companywebsite: '',
        industries: '',
        partnerurl: '',
        key: '',
      },
    }),
    computed: {
      menuforstatus () {
        return [
          {
            text: 'Sign Ups by Partner',
            value: 'deposits',
            color: 'green',
            icon: 'mdi-account-cash-outline',
            nav: '/ReportsPartnersAccounts'
          },
          {
            text: 'Sales by Partner',
            value: 'cit',
            color: 'purple',
            icon: 'mdi-transfer-right',
            nav: '/ReportPartnersSales'
          },
          {
            value: 'cashin',
            text: 'Traffic By Partner',
            color: 'orange',
            icon: 'mdi-calendar-multiple',
            nav: '/ReportPartnersTraffic'
          },
          {
            value: 'monthly',
            text: 'Monthly Volume',
            color: 'red',
            icon: 'mdi-chart-areaspline',
            nav: '/ReportPartnersVolume'
          }
        ]
      },
      validForm () {
        let validform = false
        if (this.email && this.phone && this.company && this.companywebsite && this.industries) {
          validform = true
        }
        return validform
      },
      rules () {
        const rules = []
        let urls = this.partners.map(entry => entry.partnerurl)
        if (this.unique) {
          const rule =
            v => (!urls.includes(v)) ||
              'Values must be unique and not be an existing site name'

          rules.push(rule)
        }
        if (!this.onlyspace) {
          const rule =
            v => v.trim().length || 'Name can not be only whitespace characters'

          rules.push(rule)
        }
        if (this.max) {
          const rule =
            v => (v || '').length <= this.max ||
              `A maximum of ${this.max} characters is allowed in a site name`

          rules.push(rule)
        }

        if (!this.allowSpaces) {
          const rule =
            v => !(/[ ]/.test(v)) || 'No spaces allowed'
          rules.push(rule)
        }
        if (this.filterchars) {
          const rule =
            v => !v.match(/\\t|[#\$\.]/gi) || ' $ # [ ] tabs are not allowed in a site name'
          rules.push(rule)
        }
        return rules
      },
      URLrules () {
        console.log('setting url rules')
        const rules = []
        let partnerURLs = this.$store.getters.getLoadedBlanketPartners.map(entry => entry.partnerurl)
        console.log('partner urls ', partnerURLs)

        if (this.unique) {
          const rule =
            v => (!partnerURLs.includes(v)) ||
              'URL must be unique and not be an existing partner URL'

          rules.push(rule)
        }
        if (!this.onlyspace) {
          const rule =
            v => v.trim().length || 'URL can not be only whitespace characters'

          rules.push(rule)
        }
        if (this.max) {
          const rule =
            v => (v || '').length <= this.max ||
              `A maximum of ${this.max} characters is allowed in a site name`

          rules.push(rule)
        }

        if (!this.allowSpaces) {
          const rule =
            v => !v.match(/^\S+$/) || 'No spaces are allowed'
          rules.push(rule)
        }
        if (this.filterchars) {
          const rule =
            v => !v.match(/\\t|[#\$\.]/gi) || ' $ # [ ] tabs are not allowed in a URL'
          rules.push(rule)
        }
        console.log('rules ', rules)
        return rules
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      getpartners () {
        this.partners = []
        console.log(this.$store.getters.getLoadedBlanketPartners)
        if (this.$store.getters.getLoadedBlanketPartners) {
          this.partners = this.$store.getters.getLoadedBlanketPartners
          this.partners.forEach(entry => {
            Object.assign(entry, { created: moment(entry.timestamp).format('YYYY-MM-DD HH:mm') })
          })
        }

        return this.partners
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.$store.dispatch('loadblanketpartners')
    },
    methods: {
      gettopurl (nav) {
        let url = nav
        return url
      },
      initialize () {
        this.partners = this.$store.getters.getLoadedBlanketPartners
      },
      editItem (item) {
        this.editedIndex = this.partners.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.partners.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.partners.splice(this.editedIndex, 1)
        this.$store.dispatch('removerelease', this.editedItem)

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          console.log('updating ', this.editedItem)
          Object.assign(this.partners[this.editedIndex], this.editedItem)
          this.$store.dispatch('updateBlanketPartner', this.editedItem)
        } else {
          console.log('this must be new ', this.editedItem)
          this.partners.push(this.editedItem)
          this.$store.dispatch('createBlanketPartner', this.editedItem)
        }
        this.$store.dispatch('loadblanketpartners')
        this.close()
      },
    },
  }
</script>

<style lang="scss" scoped>
.policy-numbers {
  font-size: 36px;
}
</style>
